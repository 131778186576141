/* *********************************** 
CARDS
*********************************** */
.emc-card {
  font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
  padding: 0px !important;
  border: 1px solid transparent;
  border-color: #ddd;

  .mat-card-subtitle, .mat-card-content {
      font-size: 16px;
  }

  .mat-card:not([class*=mat-elevation-z]) {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid transparent;
      border-color: #ddd;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
      box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  }
  .mat-card-header {
      padding: 10px 15px;
      color: #333;
      background-color: #f5f5f5;
      border-bottom: 1px solid transparent;
      border-color: #ddd;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
  }
  .mat-card-header .mat-card-title {
      font-size: 16px !important;
  }
  .mat-card-content {
    padding: 15px !important;
  }
  .mat-card-header-text {
       margin: 0px !important; 
  }
  .mat-card-actions, .mat-card-subtitle, .mat-card-content {
      display: block;
      margin-bottom: 0px !important;
  }
  .mat-card-actions {
      margin-left: 0px;
      margin-right: 0px;
      padding: 8px 0;
      padding: 10px 15px;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
  }
  .mat-card-actions:last-child {
      margin-bottom: 0px;
      padding-bottom: 8px !important;
  }
  .mat-card-image {
      width: 100% !important;
      margin: 0 !important;
  }
  .mat-card-avatar {
      background: #f9f9f9;
      margin-right: 15px;
      margin-top: 6px;
      border: 1px solid #ddd;
  }
}


