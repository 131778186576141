/* *********************************** 
BUTTONS
*********************************** */

button {
  font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
  font-weight: 600 !important;
}
.mat-flat-button {
  border: 1px solid #fff !important;
  line-height: 32px !important;
}
.mat-button,
.mat-raised-button {
  text-transform: none;
  margin-right: 16px !important;
  color: $emc-primary;
}
.mat-raised-button-blue {
  text-transform: uppercase;
  float: right !important;
  background-color: $emc-primary;
  color: $light-primary-text;
  margin-bottom: 10px !important;
}
.mat-raised-button-clear {
  text-transform: unset;
  float: right !important;
}
.mat-raised-button-big {
  text-transform: unset;
  width: 200px;
  font-size: 14px;
  height: 36px;
}
//extended FAB button
.extended-fab-button.mat-fab {
  position: absolute;
  float: right;
  right: 24px;
  top: 56px;
  width: unset;
  padding: 0 20px;
  border-radius: 32px;
  height: 40px;

  .extended-fab-button__text {
    font-weight: 500;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: $emc-primary;
  }

  .mat-button-wrapper {
    padding: 0;
  }
}
// remove focus highlighting for white text buttons
.mat-button-focus-overlay {
  background-color: transparent!important;
}

// adds spacing between buttons in a single row next to each other
button + button {
  margin-left: 10px !important;
}
button.mat-primary {
  color: $white;
  background-color: $btn-primary-bg !important;
  border-color: $btn-primary-border !important;

  &:focus,
  &.focus {
    color: $btn-primary-color;
    background-color:darken($btn-primary-bg, 5%);
    border-color:darken($btn-primary-border, 15%);
  }
  &:hover {
    color: $btn-primary-color;
    background-color: darken($btn-primary-bg, 5%);
    border-color: darken($btn-primary-border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $btn-primary-color;
    background-color: darken($btn-primary-bg, 5%);
    background-image: none;
    border-color: darken($btn-primary-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-primary-color;
      background-color: darken($btn-primary-bg, 7%);
      border-color: darken($btn-primary-border, 15%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $btn-primary-color;
      background-color: $btn-primary-bg;
      border-color: $btn-primary-border;
    }
  }
}
button.mat-primary-outline {
  color: $primary-dark;
  background-color: $btn-primary-outline-bg !important;
  border-color: $btn-primary-outline-border !important;
  & .badge {
    background-color: $primary-dark;
  }
  &:focus,
  &.focus {
    color: $btn-primary-outline-color;
    background-color:darken($btn-primary-outline-bg, 5%);
    border-color:darken($btn-primary-outline-border, 15%);
  }
  &:hover {
    color: $btn-primary-outline-color;
    background-color: darken($btn-primary-outline-bg, 5%);
    border-color: darken($btn-primary-outline-border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $btn-primary-outline-color;
    background-color: darken($btn-primary-outline-bg, 5%);
    background-image: none;
    border-color: darken($btn-primary-outline-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-primary-outline-color;
      background-color: darken($btn-primary-outline-bg, 7%);
      border-color: darken($btn-primary-outline-border, 15%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $btn-primary-outline-color;
      background-color: $btn-primary-outline-bg;
      border-color: $btn-primary-outline-border;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $btn-primary-outline-color;
      background-color: $btn-primary-outline-bg;
      border-color: $btn-primary-outline-border;
    }
  }
}
button.mat-secondary {
  color: $btn-secondary-color;
  background-color: $btn-secondary-bg !important;
  border-color: $btn-secondary-border !important;
  & .badge {
    background-color: $white;
    color: $brand-primary;
  }
  &:focus,
  &.focus {
    color: $btn-secondary-color;
    background-color:darken($btn-secondary-bg, 5%);
    border-color:darken($btn-secondary-border, 15%);
  }
  &:hover {
    color: $btn-secondary-color;
    background-color: darken($btn-secondary-bg, 5%);
    border-color: darken($btn-secondary-border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $btn-secondary-color;
    background-color: darken($btn-secondary-bg, 5%);
    background-image: none;
    border-color: darken($btn-secondary-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-secondary-color;
      background-color: darken($btn-secondary-bg, 7%);
      border-color: darken($btn-secondary-border, 15%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $btn-secondary-color;
      background-color: $btn-secondary-bg;
      border-color: $btn-secondary-border;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $btn-secondary-color;
      background-color: $btn-secondary-bg;
      border-color: $btn-secondary-border;
    }
  }
}
button.mat-secondary-outline {
  color: $btn-secondary-outline-color;
  background-color: $btn-secondary-outline-bg !important;
  border-color: $btn-secondary-outline-border !important;
  & .badge {
    background-color: $brand-primary;
  }
  &:focus,
  &.focus {
    color: $btn-secondary-outline-color;
    background-color:darken($btn-secondary-outline-bg, 5%);
    border-color:darken($btn-secondary-outline-border, 15%);
  }
  &:hover {
    color: $btn-secondary-outline-color;
    background-color: darken($btn-secondary-outline-bg, 5%);
    border-color: darken($btn-secondary-outline-border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $btn-secondary-outline-color;
    background-color: darken($btn-secondary-outline-bg, 5%);
    background-image: none;
    border-color: darken($btn-secondary-outline-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-secondary-outline-color;
      background-color: darken($btn-secondary-outline-bg, 7%);
      border-color: darken($btn-secondary-outline-border, 15%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $btn-secondary-outline-color;
      background-color: $btn-secondary-outline-bg;
      border-color: $btn-secondary-outline-border;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $btn-secondary-outline-color;
      background-color: $btn-secondary-outline-bg;
      border-color: $btn-secondary-outline-border;
    }
  }
}
button.mat-default {
  color: $gray-base;
  background-color: $white !important;
  border-color: #ccc !important;
  & .badge {
    color: #fff;
    background-color: $gray-base;
  }
  &:focus,
  &.focus {
    color: $gray-base;
    background-color:#e6e6e6;
    border-color:#8c8c8c;
  }
  &:hover {
    color: $gray-base;
    background-color:#e6e6e6;
    border-color:#8c8c8c;
  }
  &:active,
  .open > .dropdown-toggle & {
    color: $gray-base;
    background-color: $white;
    background-image: none;
    border-color: #ccc;

    &:hover,
    &:focus,
    &.focus {
      color: $gray-base;
      background-color:#e6e6e6;
      border-color:#8c8c8c;
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $gray-base;
      background-color: $gray-light-4;
      border-color: #ccc;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $gray-base;
      background-color: $gray-light-5;
      border-color: #ccc;
    }
  }
}
button.mat-link {
  font-weight: 400;
  color: $link-color !important;
  border-color: transparent !important;
  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}
button.mat-success {
  color: $white;
  background-color: $brand-success !important;
  border-color: darken($brand-success, 5%) !important;

  &:focus,
  &.focus {
    color: $white;
    background-color:darken($brand-success, 5%);
    border-color:darken($brand-success, 15%);
  }
  &:hover {
    color: $white;
    background-color: lighten($brand-success, 5%);
    border-color: darken($brand-success, 12%);
  }
  &:active {
    color: $white;
    background-color: darken($brand-success, 5%);
    background-image: none;
    border-color: darken($brand-success, 12%);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 1;
      background-color: lighten($brand-success, 25%);
      border-color: lighten($brand-success, 15%);
    &:hover,
    &:focus,
    &.focus {
    opacity: 1;
      background-color: lighten($brand-success, 20%);
      border-color: lighten($brand-success, 13%);
    }
  }
}
button.mat-info {
  color: $white;
  background-color: $brand-info !important;
  border-color: darken($brand-info, 5%) !important;

  &:focus,
  &.focus {
    color: $white;
    background-color:darken($brand-info, 5%);
    border-color:darken($brand-info, 15%);
  }
  &:hover {
    color: $white;
    background-color: lighten($brand-info, 5%);
    border-color: darken($brand-info, 12%);
  }
  &:active {
    color: $white;
    background-color: darken($brand-info, 5%);
    background-image: none;
    border-color: darken($brand-info, 12%);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 1;
      background-color: lighten($brand-info, 35%);
      border-color: lighten($brand-info, 25%);
    &:hover,
    &:focus,
    &.focus {
    opacity: 1;
      background-color: lighten($brand-info, 25%);
      border-color: lighten($brand-info, 15%);
    }
  }
}
button.mat-warning {
  color: $btn-warning-color;
  background-color: $btn-warning-bg !important;
  border-color: $btn-warning-border !important;
 
  &:focus,
  &.focus {
    color: $btn-warning-color;
    background-color:darken($btn-warning-bg, 5%);
    border-color:darken($btn-warning-border, 15%);
  }
  &:hover {
    color: $btn-warning-color;
    background-color: darken($btn-warning-bg, 5%);
    border-color: darken($btn-warning-border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $btn-danger-color;
    background-color: darken($btn-warning-bg, 5%);
    background-image: none;
    border-color: darken($btn-warning-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-warning-color;
      background-color: darken($btn-warning-bg, 7%);
      border-color: darken($btn-warning-border, 15%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $btn-warning-color;
      background-color: $btn-warning-bg;
      border-color: $btn-warning-border;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $btn-warning-color;
      background-color: $btn-warning-bg;
      border-color: $btn-warning-border;
    }
  }    
}
button.mat-danger {
  color: $btn-danger-color;
  background-color: $btn-danger-bg !important;
  border-color: $btn-danger-border !important;
 
  &:focus,
  &.focus {
    color: $btn-danger-color;
    background-color:darken($btn-danger-bg, 5%);
    border-color:darken($btn-danger-border, 15%);
  }
  &:hover {
    color: $btn-danger-color;
    background-color: darken($btn-danger-bg, 5%);
    border-color: darken($btn-danger-border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $btn-danger-color;
    background-color: darken($btn-danger-bg, 5%);
    background-image: none;
    border-color: darken($btn-danger-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $btn-danger-color;
      background-color: darken($btn-danger-bg, 7%);
      border-color: darken($btn-danger-border, 15%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: 0.60;
    color: $btn-danger-color;
      background-color: $btn-danger-bg;
      border-color: $btn-danger-border;
    &:hover,
    &:focus,
    &.focus {
    opacity: 0.70;
    color: $btn-danger-color;
      background-color: $btn-danger-bg;
      border-color: $btn-danger-border;
    }
  }
}

/*************************************************
BUTTON SIZING
*************************************************/

.mat-flat-button[size="xs"] {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5 !important;
  border-radius: 3px;
}
.mat-flat-button[size="sm"] {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5 !important;
  border-radius: 3px;
}
.mat-flat-button[size="lg"] {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333 !important;
  border-radius: 6px;
}

/*************************************************
BUTTON WIDTH
*************************************************/

.mat-flat-button[display="block"] {
  display: block;
  width: 100%;
}

.mat-flat-button[display="block"] + .mat-flat-button[display="block"] {
  margin-left: 0 !important;
  margin-top: 10px !important;
}