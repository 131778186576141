.full-page-dialog .mat-dialog-container {
  padding: 0px !important;

  .mat-dialog-title {
    margin: 0px;
    height: 64px;
   }

   .header-line.mat-divider{
    border: 0.5px solid grayscale($color: #5754549c);
   }

  .close-button.mat-icon-button {
    padding: 0;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    margin: 10px;
  }

  .close-button.mat-icon-button .mat-icon {
    font-size: 35px !important;
    padding-right: 10px;
    padding-bottom: 1px;
  }

  .mat-dialog-content {
    max-height: calc(100vh - 88px);
    align-content: center;
    margin: 0 16px;
    padding-top: 30px;
  }
}
