.mat-tab-group {
  font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
}
.mat-tab-label,
.mat-tab-link {
  font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
  letter-spacing: 1pt;
  text-transform: unset;
  font-weight: bold;
  color: $black;
  opacity: 1 !important;
}
.mat-tab-label-active {
  color: $emc-primary;
}

.emc-tabs {
  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    border-bottom: none !important;
  }
  .mat-tab-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 2px;
  }
  .mat-tab-label {
    color: $brand-primary;
    border-radius: 4px 4px 0 0;
    margin-right: 2px;
    padding: 4px 30px 0 !important;
    min-width: unset;
    &:hover {
      background-color : #f4f4f4;
    }
  }
  .mat-tab-label-active {
    background: linear-gradient(0deg, #fff 90%, #0077c8 10%);
    color: $black;
    outline: 1px solid #ddd !important;
    outline-radius: 4px 4px 0 0;
    border-bottom: 2px solid $white !important;
    margin-bottom: -2px !important;
    top: 1px;
    z-index: 999;
  }
  .mat-tab-body-wrapper {
    padding: 20px 10px;
  }
}

.emc-tabs-inverse {
  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    margin-top: 20px;
    margin-left: 10px;
    border-bottom: none !important;
  }
  .mat-tab-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 2px;
  }
  .mat-tab-label {
    color: $white;
    border-radius: 4px 4px 0 0;
    margin-right: 2px;
    padding: 4px 30px 0 !important;
    min-width: unset;
    &:hover {
      background-color : #0089dc !important;
    }
  }
  .mat-tab-label-active {
    background: $white;
    color: $black;
    border-bottom: 2px solid $white !important;
    margin-bottom: -2px !important;
    top: 1px;
    z-index: 999;
    &:hover {
      background: $white !important;
    }
  }
  .mat-tab-body-wrapper {
    padding: 20px 10px;
    background: #fff;
  }
}

