// Form elements
.mat-form-field {
  display: block !important;
  font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, 
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.52125em) scale(1) perspective(0) translateZ(0);
  -ms-transform: translateY(-1.28125em) scale(1);
  width: 100%;
  display: block;
}

.mat-form-field-label-wrapper {
    top: -12px;
    padding-top: 0.84375em;
}
.mat-input-element, .mat-select {
    display: block !important;
    height: 32px !important;
    width: 98% !important;
    padding: 0px 12px !important;
    font-size: 16px !important;
    line-height: 1.42857143 !important;
    color: #58595b !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
}
.mat-input-element:focus, .mat-select:focus {
    border-color: #66afe9 !important;
    outline: 0 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}
.mat-form-field-wrapper {
  padding-bottom: 20px !important;
}
.mat-form-field-underline {
  display: none !important;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    color: #333 !important;
    font-weight: normal !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #333 !important;
}
.mat-select-panel {
  margin-top: 6px;
}
.mat-select-trigger {
  margin-top: 5px;
}
textarea {
  min-height: 60px;
}

.tableContainer {
  max-height: 80vh;
  overflow: auto;

  label {
    font-size: 12px;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.82);
  }

  .mat-form-field {
    min-width: fit-content !important;
    max-width: fit-content !important;
    text-align: end;
    display: contents !important;
    font-size: 10.5px;
  }

  .mat-form-field-wrapper {
    position: unset;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: inherit;
    padding: 10px 0px 10px 0px;
  }
}
//format dense form-fields
.dense.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0;
}

.locked.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: $dark-focused;
}

.locked.mat-form-field-appearance-outline.mat-form-field-disabled .mat-select-value,
.locked.mat-form-field-appearance-outline.mat-form-field-disabled .mat-input-element:disabled {
  color: $dark-secondary-text;
}
