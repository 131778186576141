.emc-stepper {
  .mat-step-icon {
    height: 36px !important;
    width: 36px !important;
    font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
    z-index: 2 !important;
  }
  .mat-step-header {
    & .mat-step-icon {
      background-color: $white !important;
      color: $gray-light-1 !important;
      border: 1px solid $gray-light-1 !important;
    }
    & .mat-step-icon-selected {
      background-color: $brand-primary !important;
      border: 1px solid $brand-primary !important;
      color: $white !important;
    }
    & .mat-step-icon-state-edit {
      background-color: $green !important;
      border: 1px solid $green !important;
      color: $white !important;
    }
    & .mat-step-icon-state-done {
      background-color: $white !important;
      color: $brand-primary !important;
      border: 1px solid $brand-primary !important;
    }
  }
  & .mat-step-icon-state-edit + .mat-step-label > .mat-step-text-label {
    color: $brand-primary;
  }
  .mat-step-icon .mat-icon {
    top: 45% !important;
    left: 44% !important;
    transform: translate(-64%, -65%) !important;
  }
  & .mat-step-text-label {
    color: $gray;
  }
  & .mat-step-label-active {
    color: $brand-primary;
  }
  & .mat-step-header.cdk-keyboard-focused, 
  .mat-step-header.cdk-program-focused, 
  .mat-step-header:hover,
  .mat-step-header:active,
  .mat-step-header:focus {
    background-color: transparent !important;
    text-decoration: underline;
  }
  & .mat-step-label-selected > .mat-step-text-label {
    color: $brand-primary !important;
  }
  & .mat-ripple {
    display: none !important;
  }
  & .mat-horizontal-stepper-header::before, .mat-horizontal-stepper-header::after, .mat-stepper-horizontal-line {
    border-top-color: $brand-primary !important;
  }
}
.emc-stepper.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, 
[dir=rtl] .emc-stepper.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before, 
.emc-stepper.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, 
[dir=rtl] .emc-stepper.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  width: calc(42% - 12px) !important;
  width: 50% !important;
  z-index: 1 !important;
  top: 43px !important;
}
.emc-stepper.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 43px !important;
}
