//
//Design System color variables
//
$gray-base:             #333; 
$gray-dark-3:           #292929;
$gray-dark-2:           #464646;
$gray-dark-1:           #58595b;
$gray:                  #6d6e70;
$gray-light-1:          #c1c0bf;
$gray-light-2:          #d2d2d2;
$gray-light-3:          #e7e7e7;
$gray-light-4:          #f4f4f4;
$gray-light-5:          #f9f9f9;
 
$white:                 #fff;
$black:                 #000;
$orange:                #f48720;
$dark-orange:           #cc4b00;

$dark-green:            #205d2c;
$green:                 #2e8540;
$silver:                $gray-light-1;

$red:                   #b00020;
$deep-magenta:          #612a66;
$purple:                #8b4b97;
$tan:                   #d2b48c;
$gold:                  #ecb72f;
$coral:                 #d4604d;
$sky-blue:              #34b4ee;
$light-blue:            #bbdff6;
$midnight:              #252357;
$mix-primary-coral:     #452b6f;
$mix-primary-green:     #339999;
$mix-gold-coral:        #df8a3f;

$brand-primary:         #0077c8;
$primary-light-5:       #e1f5ff;
$primary-light-4:       #80d5ff;
$primary-light-3:       #1db8ff;
$primary-light-2:       #00abff;
$primary-light-1:       #0089dc;

$primary-dark:          #00467f;
$primary-dark-1:        #12406f;
$primary-dark-2:        #103963;
$primary-dark-3:        #0e3256;
$primary-dark-4:        #0c2b4a;
$primary-dark-5:        #0a243e;

$brand-link:            $brand-primary;
$brand-success:         $green;
$brand-info:            $deep-magenta;
$brand-warning:         $dark-orange;
$brand-danger:          $red;

$brand-info-10:         #f3edf4;
$brand-warning-10:      #fdf3e8;
$brand-danger-10:       #f7e5e8;
$brand-success-10:      #eaf2eb;

$text-color:              $gray-base;

$link-color:              $brand-primary;
$link-hover-color:        darken($link-color, 15%);
$link-hover-decoration:   underline;

$btn-primary-color:             $white;
$btn-primary-bg:                $primary-dark;
$btn-primary-border:            $primary-dark;

$btn-primary-outline-color:     $primary-dark;
$btn-primary-outline-bg:        $white;
$btn-primary-outline-border:    $primary-dark;

$btn-secondary-color:           $white;
$btn-secondary-bg:              $brand-primary;
$btn-secondary-border:          $brand-primary;

$btn-secondary-outline-color:   $brand-primary;
$btn-secondary-outline-bg:      $white;
$btn-secondary-outline-border:  $brand-primary;

$btn-danger-color:              $brand-danger;
$btn-danger-bg:                 $white;
$btn-danger-border:             $brand-danger;

$btn-orange-color:              $dark-orange;
$btn-orange-bg:                 $white;
$btn-orange-border:             $dark-orange;

$btn-warning-color:              $btn-orange-color;
$btn-warning-bg:                 $btn-orange-bg;
$btn-warning-border:             $btn-orange-border;

$btn-link-disabled-color:        $gray;

//
//End Design System color variables
//

$emc-primary:         $primary-dark;
$emc-accent:          $green;
$emc-warn:            $red;
$emc-warn-icon:       $red;

$emc-white:           $white;
$emc-light-gray:      $gray-light-4;

$dark-primary-text:     $gray-base;
$dark-secondary-text:   $gray;
$dark-disabled-text:    $gray-dark-1;
$dark-dividers:         $gray-light-4;
$dark-focused:          $gray-light-4;
$dark-hover:            $gray-light-5;
$light-primary-text:    $white;
$light-secondary-text:  $white;
$light-disabled-text:   $gray-light-5;
$light-dividers:        $white;
$light-focused:         $white;
$dark-cell:             #14000000;  
$light-row:             #0a000000;
$table-details:         $gray-light-3;

$dark-border: $gray-light-1;

$emc-primary-palette: (
  50: $primary-light-5,
  100: $primary-light-4,
  200: $primary-light-3,
  300: $primary-light-2,
  400: $primary-light-1,
  500: $brand-primary,
  600: $primary-dark,
  700: $primary-dark-1,
  800: $primary-dark-2,
  900: $primary-dark-3,
  A100: $primary-light-3,
  A200: $primary-light-2,
  A400: $primary-light-1,
  A700: $brand-primary,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);

$emc-accent-palette: (
  50: $brand-success-10,
  100: #c0dac5,
  200: #96c29f,
  300: #6ca979,
  400: #429153,
  500: $green,
  600: #297739,
  700: #246a33,
  800: #205d2c,
  900: #1b4f26,
  A100: $brand-success-10,
  A200: #c0dac5,
  A400: #96c29f,
  A700: #6ca979,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);
$emc-warn-palette: (
  50: $brand-danger-10,
  100: #efccd2,
  200: #df99a5,
  300: #cf6679,
  400: #bf324c,
  500: $red,
  600: #9e001c,
  700: #8c0019,
  800: #7b0016,
  900: #690013,
  A100: $brand-danger-10,
  A200: #efccd2,
  A400: #df99a5,
  A700: #cf6679,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);
