@use '@angular/material' as mat;
@import 'src/theme/emc-pallette.scss';
@import '~@angular/material/theming';
@import 'src/styles/fullPageDialog.scss';
@include mat.core();
$theme-primary: mat.define-palette($emc-primary-palette);
$theme-accent: mat.define-palette($emc-accent-palette);
$theme-warn: mat.define-palette($emc-warn-palette);
$my-theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
@include mat.all-component-themes($my-theme);
@import 'src/theme/emc-buttons.scss';
@import 'src/theme/emc-form-elements.scss';
@import 'src/theme/emc-tabs.scss';
@import 'src/theme/emc-stepper.scss';
@import 'src/theme/emc-cards.scss';

@charset "UTF-8";
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url('/assets/fonts/EOT/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/WOFF2/TTF/SourceSansPro-Light.ttf.woff2') format('woff2'),
  url('/assets/fonts/WOFF/OTF/SourceSansPro-Light.otf.woff') format('woff'),
  url('/assets/fonts/OTF/SourceSansPro-Light.otf') format('opentype'),
  url('/assets/fonts/TTF/SourceSansPro-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('/assets/fonts/EOT/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/WOFF2/TTF/SourceSansPro-Regular.ttf.woff2') format('woff2'),
  url('/assets/fonts/WOFF/OTF/SourceSansPro-Regular.otf.woff') format('woff'),
  url('/assets/fonts/OTF/SourceSansPro-Regular.otf') format('opentype'),
  url('/assets/fonts/TTF/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url('/assets/fonts/EOT/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/WOFF2/TTF/SourceSansPro-SemiBold.ttf.woff2') format('woff2'),
  url('/assets/fonts/WOFF/OTF/SourceSansPro-SemiBold.otf.woff') format('woff'),
  url('/assets/fonts/OTF/SourceSansPro-SemiBold.otf') format('opentype'),
  url('/assets/fonts/TTF/SourceSansPro-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url('/assets/fonts/EOT/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/WOFF2/TTF/SourceSansPro-Bold.ttf.woff2') format('woff2'),
  url('/assets/fonts/WOFF/OTF/SourceSansPro-Bold.otf.woff') format('woff'),
  url('/assets/fonts/OTF/SourceSansPro-Bold.otf') format('opentype'),
  url('/assets/fonts/TTF/SourceSansPro-Bold.ttf') format('truetype');
}

body {
  margin: 0px 0px -10px 0px;
  background-color: $white;
  font-size: 16px;
  font-family: "source-sans-pro", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 0px;
  color: $gray-base;
  overflow-x: hidden;
}



html,
.mat-tab-body {
  height: 100%;
}

// body {
//   margin: -10px;
//   font-family: 'Roboto', sans-serif;
//   background-color: $white;
//   overflow-x: hidden;
// }
main {
  padding: 50px;
}
.profile-menu {
  background-color:  $emc-primary !important;
 }

.mat-menu-content:not(:empty) {
  padding: unset !important;
}

.cdk-overlay-pane .mat-menu-panel {
  min-width: fit-content;
  min-height: fit-content;
  margin-left: 15px;
 }

.cdk-global-scrollblock {
  overflow-y: auto;
}

#menuControls {
  min-width: 470px;
  padding: 0px;
  margin: 0px;
}

.top-page-spacer {
  padding-top: 24px;
}

.control-stretch {
  width: 100% !important;
}


.material-icons {
  font-feature-settings: 'liga';
  font-size: 20px !important;
}

.border-grayscale {
  border: 1px solid grayscale($color: #5754549c) !important;
}

.border-bottom-grayscale {
  border-bottom: 0.25px solid rgba(86, 85, 85, 0.65) !important;

  .mat-icon-button {
    opacity: 0.52;
  }
}

.mat-header-cell {
  font-size: 13px;
}

.mat-card {
  height: 100%;
  align-content: center;
  width: 60%;
  margin-left: 20%;
}

.page-center-content {
  height: 100%;
  align-content: center;
  width: 50%;
  margin-left: 25%;
  padding-top: 70px;
}

.mat-grid-list {
  margin-top: 10%;
}

.doneIcon {
  color: $emc-accent;
}

.errorIcon {
  color: $emc-warn;
}

.inProgressIcon {
  color: gray;
}

.mat-calendar-controls {
  background-color: $emc-primary;
  color: $emc-white;
}

.mat-calendar-header {
  background-color: $emc-primary;
}

.mat-calendar-arrow {
  border-top-color: $emc-white;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}

.snackbar-error-text {
  color: $emc-white !important;
  white-space: pre-wrap;
}

.snackbar-success-text {
  color: $emc-white !important;
  white-space: pre-wrap;
}

.snackbar-error-text .mat-simple-snackbar-action {
  color: $emc-warn-icon;
  font-weight: bolder;
}

.accountSearchAutoCompletePanel {
  min-width: 350px !important;
}

/* Scrollbar custom styling*/

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.mat-select-arrow {
  opacity: 0.6;
}

#menuLob {
  .mat-menu-content:not(:empty) {
      padding: unset !important;
      margin-top: 15px;
  }
}

.labelHeading {
  margin: 15px;
  font-weight: bold;
}

.checkAll {
  margin: 15px 0px 0px 35px;
  font-weight: bold;
}

.mat-list-base .mat-list-item .mat-list-item-content-reverse, .mat-list-base .mat-list-option .mat-list-item-content-reverse {
  flex-direction: row;
  margin: 0px 0px 0px 20px;

  .mat-pseudo-checkbox {
    margin: 0px 10px;
  }
}

.mat-dialog-container .mat-dialog-actions {
  min-height: 36px;
  button {
    color: $emc-primary;
    text-decoration: uppercase;
  }
}


.jumboTooltip {
  background-color: $emc-white;
  color: $dark-primary-text !important;
  font-size: 13px !important;
  margin: 0 !important;
  padding: 16px !important;
  line-height: 16px !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

// move select option controls down
.cdk-overlay-container .cdk-overlay-pane .optionsDown {
  margin-top: 28px;
}

// inactive opacity class

.isInactive {
  opacity: 0.54;
}

// horizontal rule class added to .mat-divider
.emc-divider {
  border: 0 !important;
  border-top: 4px dotted #e9e9e9 !important;
  margin-bottom: 20px !important;
}


